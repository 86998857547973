@import "css/varsNEW";

.MuiDrawer-root .MuiPaper-root {
  padding: 8em 2em 3em;
  width: 20em;
  background: $gray_blur;
  box-shadow: 0 0.4em 1.2em #00000029;
  color: white;
  backdrop-filter: blur(30px);
  justify-content: space-between;

  &:before {
    content: "";
    width: 100%;
    height: var(--header-height);
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
  }

  .menu {
    .button {
      margin-top: 1em;
    }
  }

  .menu-item {
    position: relative;
    margin-bottom: 0.8em;
    font-size: $font18px;
    cursor: pointer;

    &.sm {
      font-size: $font16px;
    }
  }

  a.active {
    .menu-item {
      font-weight: 500;
      animation: none !important;

      &:before {
        content: "";
        position: absolute;
        background-color: #caa87555;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -0.4em 0em -0.4em -1em;
      }
    }
  }

  .invitations-menu {
    padding-top: 1.5em;
    margin-top: 1.5em;
    border-top: solid 1px #707070;

    .menu-item {
      font-weight: 500;
    }
  }

  .social-icons {
    margin-top: 1.5em;

    .opensea {
      svg {
        path:not(:first-child) {
          fill: $gray_blur;
        }
      }
    }
  }

  .invited-by {
    font-size: $font14px;
    text-align: center;
    margin: 1em 0 1.5em;
    padding-bottom: 1.75em;
    border-bottom: solid 1px #707070;

    .invited-by-text {
      font-weight: 500;
      border-bottom: solid 1px #707070;

      & > div {
        transform: translateY(50%);
        background-color: $gray_blur;
        padding: 0 1em;
      }
    }

    img {
      width: 100%;
      margin: -0.5em 0 -2.5em;
    }
  }
}

@media only screen and (max-width: 1680px) {
  .MuiDrawer-root .MuiPaper-root {
    font-size: $font15px;
  }
}

@media only screen and (max-width: 1540px) {
  .MuiDrawer-root .MuiPaper-root {
    font-size: $font14px;
  }
}

@media only screen and (max-width: 1366px) {
  .MuiDrawer-root .MuiPaper-root {
    font-size: $font13px;
  }
}

@media only screen and (max-width: 1200px) {
  .MuiDrawer-root .MuiPaper-root {
    font-size: $font12px;
  }
}

@media only screen and (max-width: 1024px) {
  .MuiDrawer-root .MuiPaper-root {
    font-size: $font16px;
  }
}
