@import "css/varsNEW";

.diamond-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 40px;

  .diamond-item {
    position: relative;

    .token-icon {
      cursor: pointer;
      border: solid 1px transparent;
      border-radius: 4px;
      padding: 0.25em 0.4em;
      align-items: flex-end;

      .token-id {
        font-size: 0.8em;
        line-height: 1em;
        color: $gold;
        font-weight: 500;
      }

      &.key {
        svg {
          transform: rotate(-90deg);
        }

        .token-id {
          transform: translateX(-0.25em);
        }
      }
    }

    &.enabled {
      .token-icon {
        opacity: 1;
      }
    }

    &.selected {
      .token-icon {
        transform: scale(1.2);
      }
    }
  }

  a.active {
    .token-icon {
      border: solid 1px white;
    }
  }

  .diamond-thumbnail {
    width: 15em;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 3em;
    z-index: 1;
  }
}
