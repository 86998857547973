@import "css/varsNEW";

.button {
  --rk-radii-connectButton: 4.5em;
  height: 2.75em;
  font-size: $font16px;
  border-radius: 4.5em;
  color: black;
  background-color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 2em;
  cursor: pointer;
  font-weight: 500;
  width: auto;
  letter-spacing: 0;
  text-transform: uppercase;
  border: none;
  box-shadow: none !important;
  transition: all 0.1s ease-out;
  white-space: nowrap;

  svg {
    margin-right: 16px;
  }

  &.icon-after {
    svg {
      margin-right: 0;
      margin-left: 0.5em;
      width: 1em;
      height: 1em;
    }
  }

  &.link {
    color: white;
    background-color: transparent;
    border: solid 1px transparent;
    padding-left: 0;
    padding-right: 0;

    span > span {
      background-color: white !important;
    }
  }

  &.gold {
    color: white;
    background-color: $gold;
    border: solid 1px transparent;
  }

  &.lg {
    font-size: $font20px;
    padding: 0 2em;
  }

  &.md {
    font-size: $font13px;
    padding: 0 2em;
  }

  &.sm {
    font-size: $font11px;
    padding: 0 2em;
  }

  &.disabled:not(.link),
  &[disabled]:not(.link) {
    cursor: not-allowed;
    background-color: $disabled_gray;
    color: $disabled_text_gray;
  }

  &.transparent {
    color: white;
    background-color: transparent;
    border: solid 1px white;
    font-weight: 400;

    span > span {
      background-color: white !important;
    }

    &.disabled,
    &[disabled] {
      opacity: 0.5;
    }
  }

  &:hover:not(.disabled) {
    transform: unset !important;

    &.gold {
      color: $gold;
      border-color: $gold;
      background-color: white;
    }

    &.transparent {
      color: black;
      background-color: white;
    }
  }

  &.is-loading {
    .btn-loader {
      position: absolute;
    }

    *:not(.btn-loader) {
      opacity: 0;
    }
  }
}
