.diamond-art {
  height: 100%;
  position: relative;

  img,
  .react-player {
    //height: 240px !important;
    //width: unset !important;
    width: 100% !important;
    height: 100% !important;
  }
}
