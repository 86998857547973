@import "css/varsNEW";

.play-button {
  cursor: pointer;
  font-size: 0.9em;
  align-items: stretch;

  * {
    font-weight: 500;
  }

  svg {
    font-size: 2.5em;
    margin-right: 0.25em;
    transform: rotate(0deg);
    color: $gold;
  }

  .links-column {
    font-size: $font22px;
    padding: 0.15em 0;

    .video-link {
      white-space: nowrap;
      text-decoration: underline;
    }
  }

  &:not(:hover) {
    animation: growShrinkInfinite 3s linear forwards infinite;

    svg {
      animation: playRotateInfinite 3s linear forwards infinite;
    }
  }

  &.second {
    &:not(:hover) {
      animation-delay: 1s;

      svg {
        animation-delay: 1s;
      }
    }
  }

  &:hover {
    .video-link {
      animation: playHoverColor 0.25s linear forwards;
    }

    svg {
      animation: playHoverRotate 0.25s linear forwards;

      path {
        animation: playHoverColor 0.25s linear forwards;
      }
    }
  }

  @keyframes playHoverColor {
    0% {
      color: white;
    }
    100% {
      color: $gold;
    }
  }

  @keyframes playHoverRotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes playRotateInfinite {
    0% {
      transform: rotate(0deg);
    }
    12.5% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes growShrinkInfinite {
    0% {
      transform: scale(1);
    }
    12.5% {
      transform: scale(1.1);
    }
    25% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
}
