@import "css/varsNEW";

.social-icons {
  a {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 0.75em;

    &.disabled {
      opacity: 1;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    &:not(:last-child) {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 0.3em;
        bottom: 0.3em;
        width: 1px;
        right: 0;
        background-color: white;
        opacity: 0.75;
      }
    }

    .icon {
      height: $font34px;

      svg {
        height: 100%;
        width: auto;

        &.MuiSvgIcon-root {
          width: unset;
        }
      }
    }

    &.twitter-link {
      .icon {
        height: 1.75em;
      }
    }

    &.tg-link.private-tg {
      &:before {
        top: 90%;
      }
    }

    &.opensea {
      .icon {
        height: 1.75em;
      }
    }

    &.substack-link {
      .icon {
        height: 1.6em;
      }
    }
  }
}
