@import "css/varsNEW";

.wallet {
  font-size: $font14px;
  font-weight: 400;
  letter-spacing: 0.05em;
  cursor: pointer;

  &:not(.connected) {
    text-decoration: underline;
  }
}
