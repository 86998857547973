@import "css/varsNEW";
@import "css/functions";

.full-screen-video {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background-color: black;
  animation: fadeIn 0.25s ease-in;

  .video-container {
    position: relative;
    min-width: 60vw;
    max-width: 80%;
    max-height: calc(80% - var(--header-height));
    margin: var(--header-height) auto 0;
    aspect-ratio: 16 / 9;
    box-shadow: 0 0 8px 4px #bbbbbb44;
    transition: all 0.1s linear;

    .page-cover {
      width: 100%;
      height: 100%;
      position: absolute;
    }

    .close {
      position: absolute;
      top: -0.5em;
      right: -1.5em;
      font-size: 2em;
    }

    .ended-cover {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: #000000aa;
      animation: fadeIn 0.5s linear;

      .play-next {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .play-next-text {
          margin: 0 0 0.25em 0.25em;
          font-weight: 500;
        }

        .play-button {
          font-size: $font25px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .full-screen-video {
    font-size: calc-font-size(11px);

    .video-container {
      max-width: 100%;
      min-width: 100%;
      margin: 0;

      .close {
        top: -1.5em;
        right: 0.5em;
      }
    }
  }
}
