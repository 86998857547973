@import "varsNEW";

.box {
  $padding_thin: 0.5em;
  $padding_wide: 0.7em;

  position: relative;
  border: solid 2px $border_gray;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: $padding_thin $padding_thin $padding_wide $padding_wide;

  .box-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    &:before {
      content: "";
      background-color: black;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 1;
      z-index: -1;
    }

    &.opaque {
      background-size: 101%;
      backdrop-filter: blur(24px);

      &:before {
        background-color: #7e7d7d;
        opacity: 0.3;
      }
    }
  }

  .icons-menu {
    position: absolute;
    right: 1.5em;
    top: 1.5em;

    .icon-twitter {
      position: relative;
      margin-right: 2em;

      &:after {
        content: "";
        position: absolute;
        right: -1.5em;
        top: 0.1em;
        bottom: 0.1em;
        width: 1px;
        background-color: white;
      }
    }

    svg {
      cursor: pointer;
      color: white;
      font-size: 1.7em !important;
      position: relative;

      &.fa-twitter {
        opacity: 1;
        font-size: 1.6em !important;
        margin-top: 0.1em;
      }

      &.gallery-icon {
        opacity: 0.7;
        margin-left: 0.3em;
      }

      &.close {
        opacity: 0.7;
        margin-left: 0.5em;
      }
    }
  }
}

.input {
  width: 100%;
  font-size: 1em;
  height: 3.05em;
  border-radius: 5em;
  background-color: transparent;
  color: white;
  border: solid 1px white;
  padding: 0.65em 1em;

  &:focus {
    outline: none;
  }

  &.validation-error {
    border-color: red;
  }

  &.full-width {
    width: 100% !important;
  }
}

textarea.input {
  border-radius: 0.65em;
}

.radio-buttons {
  display: flex;
  align-items: center;
  margin: 0 0.5em;
  color: $disabled_gray;

  .radio-button {
    border-bottom: solid 1px $disabled_gray;
    padding: 0.1em 0;
    cursor: pointer;
    width: 1.5em;
    text-align: center;
    position: relative;

    &:not(:first-child) {
      margin-left: 0.4em;
    }

    &:not(:last-child) {
      margin-right: 0.4em;

      &:after {
        color: $disabled_gray;
        content: "/";
        position: absolute;
        left: 120%;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    &.selected {
      border-color: $gold;
      color: $gold;
      cursor: default;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .box {
    .icons-menu {
      right: 0;
      top: -2.9em;
    }
  }
}
