@import "css/varsNEW";

header {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: calc(var(--header-height) + 1em);
  --logo-translate-x: -50%;

  .header-bg {
    -webkit-mask-image: -webkit-gradient(
      linear,
      left bottom,
      left 80%,
      from(rgba(0, 0, 0, 0)),
      to(rgba(0, 0, 0, 1))
    );
    backdrop-filter: blur(2.5em);
  }

  .header-internal {
    padding: 0 2.5rem;
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .minted-status {
      position: absolute;
      font-size: 1.25em;
      font-weight: 500;
      top: calc(100% - 0.5em);
      left: 1.75em;
      color: $gold;
      animation: linkHoverGold 3s ease-in-out infinite;

      svg {
        transform: rotate(-90deg) translateX(-0.2em);
      }
    }

    .wallet {
      button {
        letter-spacing: 0.1em;
        text-decoration: underline;
        padding: 0;
        font-size: 0.85em;

        & > div {
          padding: 0;
        }
      }
    }

    .vertical-sep {
      width: 1px;
      margin: 0 1em;
      background-color: white;
      height: 1.4em;
    }

    .mute-icon {
      margin-right: 1em;
      width: 1.75em;
    }
  }

  .logo-box {
    position: absolute;
    top: 1em;
    left: 50%;
    transform: translateX(var(--logo-translate-x));

    &.hidden {
      opacity: 0;
    }

    &.animate-show {
      opacity: 0;
      transform: translateX(var(--logo-translate-x)) scale(0.9);
      animation: fadeInGrowLogo 0.5s ease-out forwards;
    }

    &.animate-hide {
      animation: fadeOutShrinkLogo 0.5s ease-out forwards;
    }
  }

  .collector-btn {
    margin-right: 2.5em;
    padding-bottom: 0.1em;
    font-size: 0.9em;
  }

  svg.menu-icon {
    height: 1.25em;
    cursor: pointer;
  }

  .social-links {
    .social-link {
      margin: 0 0.4em;

      .fa-twitter {
        margin-top: 0.4em;
      }

      &.tg-link .MuiSvgIcon-root {
        margin-top: 0.3em;
        height: 1.1em;
        width: auto;
      }

      &.substack-link {
        margin-top: 0.3em;
        width: auto;

        svg {
          height: 1.1em;
        }
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @keyframes fadeInGrowLogo {
    0% {
      opacity: 0;
      transform: translateX(var(--logo-translate-x)) scale(0.9);
    }
    100% {
      opacity: 1;
      transform: translateX(var(--logo-translate-x)) scale(1);
    }
  }

  @keyframes fadeOutShrinkLogo {
    0% {
      opacity: 1;
      transform: translateX(var(--logo-translate-x)) scale(1);
    }
    100% {
      opacity: 0;
      transform: translateX(var(--logo-translate-x)) scale(0.9);
    }
  }
}

.drawer-open {
  header {
    svg.menu-icon {
      margin-left: 3px;
    }
  }
}

@media only screen and (max-width: 1366px) {
  header {
    font-size: $font15px;
  }
}

@media only screen and (max-width: 1024px) and (max-aspect-ratio: 1/1) {
  header {
    font-size: $font25px;

    .tg-link .MuiSvgIcon-root {
      margin-top: 0.5em !important;
      height: 1.8em !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  header {
    font-size: $font22px;

    .header-internal {
      padding: 0 1em;

      .minted-status {
        font-size: 1em;
        left: 0.75em;
      }
    }

    .logo-box {
      img {
        width: 5em;
      }

      &.with-text {
        &:after {
          font-size: 0.55em;
        }
      }
    }

    .tg-link .MuiSvgIcon-root {
      margin-top: 0.5em !important;
      height: 1.6em !important;
    }
  }
}

@media only screen and (max-width: 480px) {
  header {
    .header-internal {
      .vertical-sep {
        width: 0;
        margin: 0 0.5em;
      }
    }

    .logo-box {
      opacity: 1;

      img {
        width: 4em;
      }

      &.with-text {
        &:after {
          font-size: 0.45em;
        }
      }
    }

    .tg-link,
    .substack-link {
      display: none;
    }
  }
}
