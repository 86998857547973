$CDN_URL: "https://d13hr0pwt5nq9j.cloudfront.net";

$viewHeightRef: calc(100vw * 9 / 16);
$videoHeight: 400px;
$videoWidth: calc($videoHeight * 16 / 9);

// colors
$gray: #404040;
$gray_blur: #4a4a4a;
$light_gray: #a3a3a3;
$text_gray: #8a8a8a;
$border_gray: #979797;
$disabled_gray: #5c5c5c;
$disabled_text_gray: #a3a3a3;
$modal_bg_gray: #3e3e3e;
$loading_bg: #05000c;
$gold: #caa875;
$close_modal: #707070;
$border_opaque: #ffffff15;
$box_bg: #222222;
$green: #2d6e2d;
$red: #b22222;

// fonts
$font8px: 0.5em;
$font9px: 0.5625em;
$font10px: 0.625em;
$font11px: 0.6875em;
$font12px: 0.75em;
$font13px: 0.8125em;
$font14px: 0.875em;
$font15px: 0.9375em;
$font16px: 1em;
$font18px: 1.125em;
$font20px: 1.25em;
$font22px: 1.375em;
$font24px: 1.5em;
$font25px: 1.5625em;
$font28px: 1.75em;
$font30px: 1.875em;
$font32px: 2em;
$font34px: 2.125em;
$font36px: 2.25em;
$font38px: 2.375em;
$font40px: 2.5em;
$font42px: 2.625em;
$font44px: 2.75em;
$font46px: 2.875em;
$font48px: 3em;
$font50px: 3.125em;
$font54px: 3.375em;
$font80px: 5em;
