@import "css/varsNEW";

.diamond-thumbnail {
  background-color: black;
  border: solid 1px $disabled_gray;

  .react-player {
    video {
      padding-right: 1px;
    }
  }

  .token-id {
    text-align: center;
    padding: 0.6em 0;
    margin-top: -0.45em;
    color: $gold;
  }
}
